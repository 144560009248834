<template>
  <DropdownMenuSeparator v-bind="forwardedProps" class="my-2 h-px w-full bg-slate-200" />
</template>

<script setup lang="ts">
import { DropdownMenuSeparator, type DropdownMenuSeparatorProps, useForwardProps } from 'radix-vue'

const props = defineProps<DropdownMenuSeparatorProps>()

const forwardedProps = useForwardProps(props)
</script>
