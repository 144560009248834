<template>
  <Icon
    v-if="prefixIcon || variant === 'destructive'"
    :name="prefixIcon || 'heroicons:trash'"
    :class="
      cn(
        dropdownMenuItemIconStyle,
        prefixIconClass,
        variant === 'destructive' && 'text-slate-500 group-hover:text-red-500'
      )
    "
    :size="prefixIconSize || '24'" />
  <span :class="cn(dropdownMenuItemLabelStyle, labelClass)">
    {{ label }}
  </span>
  <Icon
    v-if="suffixIcon || to!! || href!! || target === '_blank'"
    :name="suffixIcon || selectLinkIcon(props)"
    :class="cn(dropdownMenuItemIconStyle, suffixIconClass)"
    :size="suffixIconSize || '24'" />
</template>

<script setup lang="ts">
import { dropdownMenuItemIconStyle, dropdownMenuItemLabelStyle } from '~/components/ui/dropdown-menu/index'
import type { DropdownMenuItemProps } from '~/components/ui/dropdown-menu/types'
import { cn } from '~/lib/utils'

const props = defineProps<DropdownMenuItemProps>()

const selectLinkIcon = ({ to, href, target }: DropdownMenuItemProps) => {
  if ((to || href) && target === '_blank') return 'heroicons:arrow-top-right-on-square-16-solid'
  if (to || href) return 'quill:chevron-right'
  return ''
}
</script>
