<template>
  <DropdownMenuItem
    v-bind="menuItemProps"
    :class="cn(dropdownMenuItemVariants({ variant }), inset && 'pl-8', props.class)"
    :as="selectMenuItemComponent(props)">
    <slot>
      <DropdownMenuAlarmItem v-if="media && variant === 'alarm'" v-bind="props" />
      <DropdownMenuUserItem v-else-if="media && variant === 'user'" v-bind="props" />
      <DropdownMenuMediaItem v-else-if="media" v-bind="props" />
      <DropdownMenuDefaultItem v-else v-bind="props" />
    </slot>
  </DropdownMenuItem>
</template>

<script setup lang="ts">
import { DropdownMenuItem, useForwardProps } from 'radix-vue'
import { computed } from 'vue'
import { NuxtLink } from '#components'
import { cn } from '@/lib/utils'
import {
  DropdownMenuDefaultItem,
  DropdownMenuAlarmItem,
  DropdownMenuUserItem,
  DropdownMenuMediaItem,
  dropdownMenuItemVariants
} from '~/components/ui/dropdown-menu/index'
import type { DropdownMenuItemProps } from '~/components/ui/dropdown-menu/types'

const props = withDefaults(defineProps<DropdownMenuItemProps>(), {
  variant: 'default'
})

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)

const menuItemProps = computed(() => {
  const { to, href, ...rest } = forwardedProps.value

  return {
    ...rest,
    to: to || href,
    href: to || href
  }
})

const selectMenuItemComponent = ({ to, href, media }: DropdownMenuItemProps) => {
  if (to || href) return typeof NuxtLink !== 'undefined' ? NuxtLink : 'a'
  if (media) return 'div'
  return 'button'
}
</script>
