<template>
  <DropdownMenuPortal>
    <DropdownMenuSubContent v-bind="forwarded" :class="cn(dropdownMenuContentStyle, props.class)">
      <slot />
    </DropdownMenuSubContent>
  </DropdownMenuPortal>
</template>

<script setup lang="ts">
import { DropdownMenuSubContent, type DropdownMenuSubContentEmits, useForwardPropsEmits } from 'radix-vue'
import { computed } from 'vue'
import type { DropdownMenuSubContentProps } from './types'
import { cn } from '@/lib/utils'
import { dropdownMenuContentStyle } from '~/components/ui/dropdown-menu/index'

const props = defineProps<DropdownMenuSubContentProps>()
const emits = defineEmits<DropdownMenuSubContentEmits>()

const delegatedProps = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>
