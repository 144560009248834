<template>
  <DropdownMenuSubTrigger v-bind="forwardedProps" :class="cn(inset && 'pl-8')">
    <slot />
  </DropdownMenuSubTrigger>
</template>

<script setup lang="ts">
import { DropdownMenuSubTrigger, useForwardProps } from 'radix-vue'
import type { DropdownMenuSubTriggerProps } from '~/components/ui/dropdown-menu/types'
import { cn } from '~/lib/utils'

const props = defineProps<DropdownMenuSubTriggerProps>()

const forwardedProps = useForwardProps(props)
</script>
