<template>
  <DropdownMenuLabel v-bind="forwardedProps" :class="cn(dropdownMenuHeadingVariants({ variant }), inset && 'pl-8')">
    <slot />
  </DropdownMenuLabel>
</template>

<script setup lang="ts">
import { DropdownMenuLabel, useForwardProps } from 'radix-vue'
import { computed } from 'vue'
import { cn } from '@/lib/utils'
import { dropdownMenuHeadingVariants } from '~/components/ui/dropdown-menu/index'
import type { DropdownMenuLabelProps } from '~/components/ui/dropdown-menu/types'

const props = withDefaults(defineProps<DropdownMenuLabelProps>(), {
  variant: 'menu'
})

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>
