<template>
  <DropdownMenuRoot v-bind="forwarded" v-model:open="model">
    <DropdownMenuTrigger as-child>
      <slot :open="model">
        <Button icon="heroicons:archive-box-arrow-down-16-solid" />
      </slot>
    </DropdownMenuTrigger>
    <ClientOnly>
      <DropdownMenuContent
        :align
        :is-loading="loading"
        :is-empty="!items || !items.length"
        :has-footer="!!footer"
        :class="props.class"
        :disabled="!portal">
        <template v-if="heading" #heading>
          <DropdownMenuHeading variant="menu">{{ heading }}</DropdownMenuHeading>
        </template>
        <template #body>
          <template v-for="(groups, index) in items" :key="index">
            <DropdownMenuGroup :groups="groups">
              <template #default="{ item }">
                <slot :name="item.slot || 'item'" :item="item" />
              </template>
            </DropdownMenuGroup>
            <DropdownMenuSeparator v-if="index < items.length - 1" />
          </template>
        </template>
        <template v-if="instance?.slots?.['footer'] || footer" #footer>
          <slot name="footer">
            <DropdownMenuFooter
              :item="
                typeof footer !== 'undefined' || typeof footer !== 'boolean' ? (footer as DropdownMenuItems) : undefined
              " />
          </slot>
        </template>
        <template #loading-state>
          <slot name="loading-state" />
        </template>
        <template #empty-state>
          <slot name="empty-state" />
        </template>
      </DropdownMenuContent>
    </ClientOnly>
  </DropdownMenuRoot>
</template>

<script setup lang="ts">
import { DropdownMenuRoot, type DropdownMenuRootEmits, useForwardPropsEmits } from 'radix-vue'
import { Button } from '~/components/ui/button'
import {
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuSeparator,
  DropdownMenuHeading,
  DropdownMenuFooter
} from '~/components/ui/dropdown-menu'
import type { DropdownMenuItems, DropdownMenuProps } from '~/components/ui/dropdown-menu/types'

const instance = getCurrentInstance()

const props = withDefaults(defineProps<DropdownMenuProps>(), {
  portal: true
})
const emits = defineEmits<DropdownMenuRootEmits>()
const model = defineModel<boolean>('open', {
  default: false
})

const forwarded = useForwardPropsEmits(props, emits)
</script>
