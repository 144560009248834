<template>
  <DropdownMenuGroup v-bind="props">
    <template v-for="(item, index) in groups" :key="index">
      <template v-if="item">
        <DropdownMenuHeading v-if="item.type === 'heading'" variant="group">{{ item.label }}</DropdownMenuHeading>
        <template v-else>
          <component :is="item?.modal || 'div'" v-bind="item.modalProps">
            <component
              :is="item.children ? DropdownMenuSub : DropdownMenuItem"
              v-bind="item"
              :sub-items="item?.children"
              :trigger-item="item"
              :variant="item.type"
              @select="(e: Event) => handleSelect(e, item)">
              <slot :item="item" />
            </component>
          </component>
        </template>
      </template>
    </template>
  </DropdownMenuGroup>
</template>

<script setup lang="ts">
import { DropdownMenuGroup } from 'radix-vue'
import { DropdownMenuHeading, DropdownMenuItem, DropdownMenuSub } from '~/components/ui/dropdown-menu/index'
import type { DropdownMenuGroupProps, DropdownMenuItems } from '~/components/ui/dropdown-menu/types'

const props = defineProps<DropdownMenuGroupProps>()

const handleSelect = (e: Event, item: DropdownMenuItems) => {
  if (item?.modal || item?.preventClose) {
    e.preventDefault()
    e.stopPropagation()
  }
}
</script>
