import { cva } from 'class-variance-authority'

export { default as DropdownMenu } from './DropdownMenu.vue'
export { default as DropdownMenuTrigger } from './DropdownMenuTrigger.vue'
export { default as DropdownMenuContent } from './DropdownMenuContent.vue'
export { default as DropdownMenuGroup } from './DropdownMenuGroup.vue'
export { default as DropdownMenuItem } from './DropdownMenuItem.vue'
export { default as DropdownMenuDefaultItem } from './DropdownMenuDefaultItem.vue'
export { default as DropdownMenuAlarmItem } from './DropdownMenuAlarmItem.vue'
export { default as DropdownMenuUserItem } from './DropdownMenuUserItem.vue'
export { default as DropdownMenuMediaItem } from './DropdownMenuMediaItem.vue'
export { default as DropdownMenuSeparator } from './DropdownMenuSeparator.vue'
export { default as DropdownMenuHeading } from './DropdownMenuHeading.vue'
export { default as DropdownMenuSub } from './DropdownMenuSub.vue'
export { default as DropdownMenuSubTrigger } from './DropdownMenuSubTrigger.vue'
export { default as DropdownMenuSubContent } from './DropdownMenuSubContent.vue'
export { default as DropdownMenuFooter } from './DropdownMenuFooter.vue'
export { default as DropdownMenuEmptyState } from './DropdownMenuEmptyState.vue'

export const dropdownMenuContentStyle = tw`bg-popover text-popover-foreground z-50 flex max-h-[280px] min-w-52 max-w-80 flex-col overflow-hidden rounded-xl bg-white-950 shadow data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2`

export const dropdownMenuContentScrollBoxVariants = cva('overflow-y-auto overflow-x-hidden p-2 ', {
  variants: {
    variant: {
      footer: 'max-h-[232px]',
      default: ''
    }
  }
})

export const dropdownMenuHeadingVariants = cva('', {
  variants: {
    variant: {
      menu: 'px-2 py-1 text-sm font-bold leading-5 text-slate-900',
      group: 'py-1.5 pl-1 pr-3 text-xs font-medium leading-4 text-slate-600'
    }
  }
})

export const dropdownMenuItemVariants = cva(
  'focus:bg-accent focus:text-accent-foreground group relative flex w-full cursor-pointer select-none items-center rounded px-2 py-1 text-sm font-normal leading-5 text-slate-900 outline-none transition-colors data-[disabled]:pointer-events-none data-[disabled]:text-slate-400',
  {
    variants: {
      variant: {
        active: 'bg-blue-100 text-blue-700',
        default: 'hover:bg-slate-200 active:bg-blue-100 active:text-blue-700',
        destructive:
          'py-1 pl-2 pr-3 text-slate-900 hover:bg-red-200 hover:text-red-600 active:bg-red-300 active:text-red-600',
        user: 'hover:bg-slate-200 active:bg-blue-100',
        alarm: 'hover:bg-slate-200 active:bg-blue-100'
      }
    }
  }
)

export const dropdownMenuItemLabelStyle = tw`w-full px-2 py-0.5 text-left`
export const dropdownMenuItemIconStyle = tw`peer h-6 min-h-6 w-6 min-w-6`
