<template>
  <DropdownMenuPortal>
    <DropdownMenuContent v-bind="forwarded" :class="cn(dropdownMenuContentStyle, props.class)">
      <div
        :class="cn('scroll-box', dropdownMenuContentScrollBoxVariants({ variant: hasFooter ? 'footer' : 'default' }))">
        <slot name="heading" />
        <DropdownMenuLoadingState v-if="isLoading">
          <slot name="loading-state" />
        </DropdownMenuLoadingState>
        <DropdownMenuEmptyState v-else-if="isEmpty">
          <slot name="empty-state" />
        </DropdownMenuEmptyState>
        <slot v-else name="body" />
      </div>
      <slot name="footer" />
    </DropdownMenuContent>
  </DropdownMenuPortal>
</template>

<script setup lang="ts">
import { DropdownMenuContent, type DropdownMenuContentEmits, DropdownMenuPortal, useForwardPropsEmits } from 'radix-vue'
import { cn } from '@/lib/utils'
import DropdownMenuLoadingState from '~/components/ui/dropdown-menu/DropdownMenuLoadingState.vue'
import {
  dropdownMenuContentScrollBoxVariants,
  dropdownMenuContentStyle,
  DropdownMenuEmptyState
} from '~/components/ui/dropdown-menu/index'
import type { DropdownMenuContentProps } from '~/components/ui/dropdown-menu/types'

const props = withDefaults(defineProps<DropdownMenuContentProps>(), {
  sideOffset: 4
})
const emits = defineEmits<DropdownMenuContentEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<style scoped>
.scroll-box::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
</style>
