<template>
  <DropdownMenuSub v-bind="forwarded">
    <DropdownMenuSubTrigger :class="dropdownMenuItemVariants({ variant: 'default' })" v-bind="triggerItem">
      <slot>
        <Media v-if="triggerItem.media" v-bind="triggerItem.media" />
        <template v-else>
          <Icon
            v-if="triggerItem.prefixIcon"
            :name="triggerItem.prefixIcon"
            :class="cn(dropdownMenuItemIconStyle, triggerItem.prefixIconClass)" />
          <span :class="cn(dropdownMenuItemLabelStyle, triggerItem.labelClass)">{{ triggerItem.label }}</span>
          <Icon
            name="quill:meatballs-h"
            :class="cn('ml-auto', dropdownMenuItemIconStyle, triggerItem.suffixIconClass)" />
        </template>
      </slot>
    </DropdownMenuSubTrigger>
    <DropdownMenuSubContent>
      <div :class="dropdownMenuContentScrollBoxVariants()">
        <template v-for="(groups, index) in subItems" :key="index">
          <DropdownMenuGroup :groups="groups">
            <template #default="{ item }">
              <slot :name="item.slot || 'item'" :item="item" />
            </template>
          </DropdownMenuGroup>
          <DropdownMenuSeparator v-if="index < subItems.length - 1" />
        </template>
      </div>
    </DropdownMenuSubContent>
  </DropdownMenuSub>
</template>

<script setup lang="ts">
import { DropdownMenuSub, type DropdownMenuSubEmits, useForwardPropsEmits } from 'radix-vue'
import {
  DropdownMenuGroup,
  DropdownMenuSeparator,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
  dropdownMenuContentScrollBoxVariants,
  dropdownMenuItemIconStyle,
  dropdownMenuItemLabelStyle,
  dropdownMenuItemVariants
} from '~/components/ui/dropdown-menu/index'
import type { DropdownMenuSubProps } from '~/components/ui/dropdown-menu/types'
import { Media } from '~/components/ui/media'
import { cn } from '~/lib/utils'

const props = defineProps<DropdownMenuSubProps>()
const emits = defineEmits<DropdownMenuSubEmits>()

const forwarded = useForwardPropsEmits(props, emits)
</script>
